/* General Styles */
.table {
  margin: 20px;
}

.loading {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

/* Loading Styles */
.divLoading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body1Loading {
  margin-top: 16px;
}

.body2Loading {
  text-align: center;
}

/* Container Styles */
.generalContainer {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
}

.gridContainer {
  display: flex;
  flex-direction: column;
  margin: 30px 0 200px;
  background-color: #fefefe;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Title Styles */
.title {
  margin-left: 10px;
  padding: 20px;
}

/* Selection Area Styles */
.selectArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 50px;
  padding-bottom: 10px;
}

.titleSelectArea {
  margin-bottom: 20px;
  padding-left: 20px;
}

/* Form Control Styles */
.gridFormControl {
  display: flex;
  justify-content: flex-start;
  padding: 20px;
}

/* Card Area Styles */
.cardArea {
  display: flex;
  justify-content: space-around;
  padding: 50px;
}

/* Error Area Styles */
.errorArea {
  margin: auto;
  padding: 50px;
}
